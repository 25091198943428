<template>
    <div class="blog-item" :class="item.id === last ? 'last' : ''">
        <div class="blog-item__image">
            <router-link :to="item.img_src">
                <img
                    v-webp:src="require(`../../assets${item.img_src}.webp`)"
                    :src="require(`../../assets${item.img_src}.jpg`)"
                    :alt="`photo-${item.id}`"
                    loading="lazy"
                />
            </router-link>
        </div>
        <div class="blog-item__title">
            <router-link :to="item.img_src">{{ item.title }}</router-link>
        </div>
        <div class="blog-item__content" v-html="item.desc"></div>
        <div class="btn-read-more primary contacts-button small">
            <router-link :to="item.img_src" class="button-content">
                Read more
                <span class="icon">
                    <img v-svg-inline :data-src="require('@/assets/icons/rarr-icon.svg')" alt="" />
                </span>
            </router-link>
        </div>
    </div>
</template>
<script>


export default {
    name: 'SidebarBuilder',

    data() {
        return {

        }
    },
    props: {
        menu_item: String,
        item: Object,
        last: String
    }
}
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.blog-item.last {
    margin-bottom: 0px !important;

}

.blog-item {
    .button {
        padding: 10px 24px;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    margin-bottom:60px;

    @include media(">=tablet-xl") {
        margin-bottom: 120px;
    }

    color:$black;

    &__image {
        max-width: 1147px;

        & img {
            cursor: pointer;
            max-width: 100%;
        }
    }

    &__title {
        &:hover {
            color: $red;
        }

        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        padding-bottom:16px;
        padding-top:20px;

        @include media(">=tablet") {
            padding-top: 40px;
            font-size: 30px;
            line-height: 36px;
        }

        @include media(">=desktop") {
            font-size: 40px;
            line-height: 48px;
        }
    }

    &__content {
        padding-bottom: 10px;
        font-size: 20px;
        line-height: 24px;

        @include media(">=tablet") {
            padding-bottom: 40px;
            font-size: 20px;
            line-height: 32px;
            padding-bottom: 20px;
        }

        a {
            color: $red;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .button-content {
        border-radius: 26px;
        padding: 8px 24px;
        background: $red;
        color: $white;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        cursor: pointer;
        width: 182px;
        background-image: linear-gradient($darkRed, $darkRed);
        background-repeat: no-repeat;
        transition: background-size .3s, color .3s;
        background-size: 0% 100%;

        &:hover {
            background-size: 100% 100%;
            color: $white;
        }
    }

    .icon {
        padding-top: 4px;
        margin-left: 15px;
    }
}
</style>
