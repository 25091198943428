<template>
  <div v-for = "item in blog" :key="item.id">
    <BlogContentBuilder :item="item" :last="last"/>
  </div>
</template>
<script>
import BlogContentBuilder from './BlogContentBuilder.vue';

export default {
    components:{
        BlogContentBuilder
    },
    computed:{
      last() {
        return this.blog.find(item => this.blog.indexOf(item) === this.blog.length - 1).id
      }

    },
    data(){
        return{
        }
    },
    props:{
      blog:Array
    }
}
</script>
